@import '../../common.scss';

@mixin expander() {
  .footer_container {
    .expander {
      position: absolute;
      display: block;
      right: 10px;
      top: 10px;
      font-size: 20px;
    }
  }
}

.footer_container {
  background: linear-gradient(96.87deg, #2c73d6, #2365bd);
  padding: 1rem 2.5rem;
  font-family: 'Montserrat';

  display: flex;

  a {
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  .offices {
    width: 50%;
    color: #fff;
    margin-bottom: 40px;
    .title-container {
      // display: flex;
      // align-items: center;

      .line {
        margin-left: 8px;
        height: 1px;
        background-color: #ffffff;
        width: 80%;
        background: linear-gradient(
          90deg,
          #ffffff 0%,
          rgba(196, 196, 196, 0) 100%
        );
        opacity: 0.2;
        margin-bottom: 8px;
      }
    }

    .offices-content {
      display: flex;
      padding-top: 12px;

      ul {
        list-style-type: none;
        display: flex;
      }

      .address-container {
        width: 200px;

        img {
          padding-left: 10px;
          padding-bottom: 3px;
        }

        li {
          font-family: 'Roboto';
          font-weight: 600;
          font-size: 14px;
          padding-bottom: 9px;
        }

        .address-lines {
          font-family: 'Roboto';
          font-weight: normal;
          font-size: 14px;
          padding: 0px;
          line-height: 21px;
        }
      }
    }
  }
  .expander {
    display: none;
  }
  .footer-content {
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: 1200px;
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    max-width: 250px;
    margin: 3rem 3rem auto 0rem;
    flex-direction: column;
    flex-shrink: 1;
    img {
      width: 100px;
      margin-left: -6px;
    }

    .code-is {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #fff;
    }

    .copyright {
      span {
        padding-right: 4px;
      }
    }
  }

  .footer-title {
    font-size: 14px;
    line-height: 21px;
    position: relative;
    padding-bottom: 12px;
  }

  .data_container {
    position: relative;
    display: flex;
    // flex-wrap: wrap;
    max-width: 1200px;
    width: 65%;
    margin: 3rem 0px 1rem;

    .footer-hr {
      visibility: hidden;
    }
  }

  .links {
    color: #fff;
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 25%;

    Image {
      vertical-align: middle;
      border-style: none;
      width: 120px;
      margin-right: 20px;
    }

    ul {
      list-style-type: none;
      text-align: left;
      z-index: 2;
      position: relative;
      padding: 0;
      min-width: 100px;
      letter-spacing: normal;
      margin-bottom: 2rem;
    }

    li {
      padding: 5px 50px 5px 0;
      font-size: 0.875rem;
      font-weight: 400;
      font-family: $Roboto;
      font-style: normal;
      line-height: 21px;
    }
  }

  .address {
    line-height: 24px;
    width: 32%;
    margin: 5% 20px 20px;
    padding: 20px;
    text-align: center;

    span {
      font-size: 0.95rem;
      line-height: 28px;
      color: #fff;
      max-width: 200px;
    }
  }

  .social_media_links {
    color: #fff;
    display: flex;
    width: 20%;
    margin-left: 86%;

    a {
      padding: 10px;

      &:hover {
        color: #000;
      }
    }
  }

  .copyright {
    color: #fff;
    padding-top: 1rem;
    font-size: 14px;
    letter-spacing: normal;
    font-family: 'Roboto';
    font-style: normal;
    line-height: 21px;
  }
}

@media (min-width: 1300px) {
  .footer_container {
    /*padding: 1rem 2rem;*/

    .data_container {
      // flex-wrap: wrap;
      margin: 60px auto 16px;
      justify-content: center;
      width: 1200px;
    }

    .offices {
      width: 50%;

      .title-container {
        display: flex;
        align-items: center;
      }
      .offices-content {
        display: flex;
        flex-direction: column;

        ul {
          padding: 0px;
        }

        .address-lines {
          font-family: 'Roboto';
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .links {
      width: 50%;
      padding: 0;

      ul {
        padding: 0;
      }

      li {
        padding: 12px 0 0;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .address {
      line-height: 24px;
      width: 25%;
      margin: 4% 0 0;
      padding: 15px;
    }

    .social_media_links {
      color: #fff;
      width: 20%;
      margin-left: 0;
      margin-top: 2%;
      padding-left: 10%;

      a {
        padding: 5px;
      }
    }

    hr {
      width: 100%;
      margin: auto;
    }

    .footer-bottom {
      .copyright {
        padding-right: 65px;
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  @include expander();
  .footer_container {
    display: block;
    padding-bottom: 100px;
    .data_container {
      display: block;
      margin: 0%;
    }
    .footer-content {
      padding-top: 2rem;
      display: block;
    }
    .footer-bottom {
      max-width: 90%;
      display: block;
      margin: 0 20px 20px 20px;
      .offices {
        width: 100%;

        .footer-title {
          text-align: left;
          padding: 10px;
          border-top: 1px solid hsla(0, 0%, 100%, 0.4117647059);
        }

        .offices-content {
          display: flex;
        }
      }
    }

    .copyright {
      padding: 20px 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      margin-right: 0%;
      font-family: Roboto;
    }
  }
}

@media (max-width: 980px) {
  .footer_container {
    padding: 0;

    .links {
      width: 50%;
      padding: 0;

      ul {
        text-align: center;
        padding: 0;
      }

      li {
        padding: 15px 0 0;
      }
    }

    .address {
      line-height: 24px;
      width: 50%;
      margin: 10% 0 0;
      padding: 0;
    }

    .social_media_links {
      color: #fff;
      display: flex;
      align-items: center;
      margin: 0 auto;
      width: auto;

      a {
        padding: 5px;
      }
    }
  }
}

@media (max-width: 648px) {
  @mixin expander() {
    .expander {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
    }
  }

  .footer_container {
    padding: 0;
    padding-bottom: 100px;

    .footer-title {
      text-align: left;
      padding: 10px;
      border-top: 1px solid hsla(0, 0%, 100%, 0.4117647059);
    }

    .data_container {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 10px;
      width: 95%;
    }

    .offices {
      width: 100%;
      margin-bottom: 0;

      // .title-container {
      //   display: flex;
      //   width: 80%;

      // }

      .mobile-view {
        height: 0;
        overflow: hidden;
        text-align: left;
        transition: height 0.3s ease;
        margin: 0;

        li {
          padding: 5px 0;
        }
      }

      .expanded {
        transition: height 0.3s ease;
        max-height: 500px;
        padding: 10px;
        height: 415px;
      }

      .line {
        display: none;
      }

      .offices-content-mobile {
        width: 100%;

        .address-container {
          padding-bottom: 5px;

          li {
            font-family: 'Roboto';
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
          }

          .address-lines {
            font-family: 'Roboto';
            font-weight: normal;
            font-size: 14px;
            padding: 0px;
            line-height: 21px;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          padding: 0 10px;
        }
      }
    }

    .links {
      width: 100%;
      padding: 0;

      &:nth-child(4) {
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.4117647059);
      }

      .mobile-view {
        height: 0;
        overflow: hidden;
        text-align: left;
        transition: height 0.3s ease;
        margin: 0;

        li {
          padding: 5px 0;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: normal;
        }
      }

      .expanded {
        transition: height 0.3s ease;
        max-height: 500px;
        padding: 10px;
        height: 180px;
      }
    }

    .address {
      line-height: 24px;
      width: 100%;
      margin: 10% 0 0;
      padding: 0;
    }

    .social_media_links {
      color: #fff;
      display: flex;
      align-items: center;
      margin: 10% auto 0;
      width: auto;

      a {
        padding: 5px;
      }
    }

    // .copyright {
    //   padding-right: 0;
    // }
  }
}
