@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);

@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
$Roboto: 'Roboto', sans-serif;

.ScrollUpButton__Container {
  bottom: 100px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.col-md-4 {
  padding-right: 8px;
  padding-left: 8px;
}

.mon-300 {
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
}

.mon-500 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.mon-600 {
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.mon-700 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.robo-300 {
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
}

.robo-700 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
.robo-400 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.w-50 {
  width: 50%;
}

.w-80 {
  width: 80%;
  margin: auto;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #menu-toggle {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .PhoneInput {
    display: flex !important;
  }
  .m-m-100 {
    min-width: 100%;
    div {
      width: auto !important;
    }
  }

  .m-m-90 {
    min-width: 90%;
  }

  .open .first,
  .rect {
    transition: all 0.3s linear;
  }

  .open {
    .first {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      position: absolute;
      top: 10px;
      right: 0;
    }

    .hide {
      display: none;
    }

    .second {
      right: 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 10px;
      transition: all 0.3s linear;
      position: absolute;
    }
  }
}

.hover:hover {
  cursor: pointer;
}

.simple-close {
  position: absolute;
  right: 100px;
  z-index: 1;
}

.load-center {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
