.eventnav {
  position: fixed;
  z-index: 100;
  width: 100%;
  background: linear-gradient(96.87deg, #2c73d6, #2365bd);

  &.invertColor {
    background: #f8f8f8;

    .navbar-container {
      background: #f8f8f8;
    }
  }
  .title-design {
    padding-top: 80px;
    padding-bottom: 16px;
  }
  .title-contact{
    padding-top: 60px;
    padding-bottom: 36px;
    
  }

  .navbar-container {
    background: linear-gradient(96.87deg, #2c73d6, #2365bd);
    position: relative;
    max-width: 1200px;

    .nav-item {
      color: #fff;
    }

    .invertColor-nav-item {
      color: rgba(7, 23, 45, 0.87);
      border-color: rgba(7, 23, 45, 0.87);
    }

    #nav-icon1 span,
    #nav-icon3 span,
    #nav-icon4 span {
      background: #fff;
    }
  }

  .newcontainer {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 15px;

    .button-container {
      margin: 0;
    }

    .arrow-event {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      h1 {
        font-weight: 700;
        color: #fff;
        font-size: 3rem;
        transition: all 0.3s ease-in;
        margin: 0 0 0 0px;
      }

      .subtitle {
        font-family: 'Montserrat', sans-serif;
        font-size: 40px;
        line-height: 48px;
        color: #fff;

        &.our-work-subtitle {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 140%;

          .second-line {
            margin-bottom: 24px;
          }
        }

        .second-line {
          margin-bottom: 66px;
        }
      }
    }

    .dotsImg {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.visibleOnMobile {
  display: none;
  line-height: normal;
}

.flex {
  .navbar-container {
    position: fixed;
  }

  .newcontainer {
    padding-top: 75px;

    .nav-top {
      display: flex;

      h1 {
        transition: all 0.3s ease-out;
        font-size: 2rem;
      }
    }
  }
}

.white-border {
  border: 1px solid;
}

@media only screen and (max-width: 575px) {
  .eventnav .newcontainer {
    max-width: 90% !important;

    .arrow-event {
      .about-us-title {
        padding: 0px;
      }
    }
  }

  .flex .newcontainer {
    padding-top: 60px;
  }
}

/*# sourceMappingURL=main.30c6dfc2.chunk.css.map */
